import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import axios from 'axios';
import "../style.css";
import config from '../../../config';
import AddinItem from '../../../Components/AddinItem';
import AddinTabs from '../../../Components/Tabs/AddinTabs.jsx';

import DiaryBaseImage from "../../../Assets/images/diary_base.png";
import InsideCoverBinder from "../../../Assets/images/insideCoverBinder.png";
import EmptyPage from "../../../Assets/images/EmptyPage.jpg";
import LensIcon from "../../../Assets/images/lens.png"
import CloseIcon from "../../../Assets/images/cross.png"


export default function HealthPage({updatePriceBox, updateTabPage}) {
  const [templateList, setTemplateList] = useState([])
  const [templateSelected, setTemplateSelected] = useState([])
  const [leftPageImage, setLeftPageImage] = useState(EmptyPage)
  const [rightPageImage, setRightPageImage] = useState(EmptyPage);
  const [priceBox, setPriceBox] = useState(1);
  const [fullView, setFullView] = useState("")
  const [largeView, setLargeView] = useState(false)


  //load api data
  useEffect(() => {
    const BACKEND_URL = config.BACKEND_URL;
    
    axios.get(`${BACKEND_URL}/api/V1/addins/Health`)
      .then(response => {
        const { templateList } = response.data.result;
        setTemplateList(templateList);
      })
      .catch(error => {
        console.error('Error fetching layout data:', error);
      });

      //getting session data
    var sessionData = JSON.parse(sessionStorage.getItem("Addins")); 
    if(sessionData!==null){

      const HealthList = sessionData.Health;

      if(HealthList!==undefined){
        setTemplateSelected((HealthList)? HealthList:[]);

        //show images of select template
        const index1 = HealthList.findIndex((item) => item.id === HealthList[0].templateId);
        templateList[index1] !== undefined && setLeftPageImage(templateList[index1].image);
        templateList[index1] !== undefined && setRightPageImage(templateList[index1].image2);
      }

    }

    updatePriceBox(priceBox+1);

  }, [ ]);


  //save data in session
  useEffect(() => { 
    var NewSessionData = JSON.parse(sessionStorage.getItem("Addins")); 
    if(NewSessionData!==null){
      NewSessionData['Health']=templateSelected;
      sessionStorage.setItem("Addins", JSON.stringify(NewSessionData));
    }

    updatePriceBox(priceBox+1);
    
  }, [templateSelected])


  

  const handleTemplateSelected = (templateId, templateTitle, templateImage, optType, count, tempSelected) => {
    // Check if the item is already selected and toggle its selection
    // Check if templateId already exists in selectedData
    
    if(tempSelected){
      const index = templateSelected.findIndex((item) => item.templateId === templateId);
      if (index === -1) {
        // If templateId is not found, add a new item
        setTemplateSelected([...templateSelected, { templateId, templateTitle, templateImage, optType, count }]);
      } else {
        // If templateId exists, update the existing item
        const updatedData = [...templateSelected];
        updatedData[index] = { templateId, templateTitle, templateImage, optType, count };
        setTemplateSelected(updatedData);
      }

      //show images of select template
      const index1 = templateList.findIndex((item) => item.id === templateId);
      templateList[index1] !== undefined && setLeftPageImage(templateList[index1].image)
      templateList[index1] !== undefined && setRightPageImage(templateList[index1].image2)
    }else{
      if(templateSelected!==undefined && templateSelected.length>0){
        const updatedData = templateSelected.filter((item) => item.templateId !== templateId);
        setTemplateSelected(updatedData);

        //show next template images from selected Templated list. if no template seclected then it show empty image
        const id = updatedData.length>0? updatedData[0].templateId:0;
        if(id>0){
          const index1 = templateList.findIndex((item) => item.id === id);
          templateList[index1] !== undefined && setLeftPageImage(templateList[index1].image)
          templateList[index1] !== undefined && setRightPageImage(templateList[index1].image2)
        }else{
          setLeftPageImage(EmptyPage);
          setRightPageImage(EmptyPage);
        }
      }
    }

    
    
  }
  
  const handleShowTemplate =(template)=>{
    template !== undefined && setLeftPageImage(template.image)
    template !== undefined &&  setRightPageImage(template.image2)
  }

  return (
    <>
      <Box className="PageInnerBox">
        <Box className="LeftPanelBox">

          <AddinTabs selectedTab='Health' updateTab={updateTabPage} />

          <Box className="LeftPanelInner">
  
            <Box className="TemplateContainerBox">

              <Box className="templateBox">
                <Typography className='templateHeader'>Choose your template</Typography>
                <Box className="templateCardBox">
                  {templateList.map((template, index) => (
                    <AddinItem key={`addin-${template.id}`} Type="Addins" templateData={template} OptSelect={'Weekly'} addinSelected={templateSelected} handleSelectedAddin={handleTemplateSelected} handleCheckTemplate={handleShowTemplate} />
                  ))}
                </Box>
              </Box>
            
            </Box>
          
          </Box>

          <Box onClick={ () => window.location.href  = "/dates" } mt={2} className="st2InputBtn deskbtn">
            <Typography>Next</Typography>
          </Box>
          
        </Box>

        <Box className="RightPanelBox">

          <Box className={largeView ? 'PreviewOuter largeview' : 'PreviewOuter '}>
            <Box mt={3} className="PreviewContainer addins">
              <img src={DiaryBaseImage} className='diray_base' alt=""/>
              <Box className="diray_inner">
                <Box mr={1} className="diray_page leftpage">
                  <Box className="dp1">
                    <img src={leftPageImage} className='PageSubImages'  alt=''/>
                  </Box>
                </Box>
                <Box ml={1} className="diray_page rightpage">
                  <img className='insideCover_binder' src={InsideCoverBinder} />
                  <Box className="dp2">
                    <img src={rightPageImage} className='PageSubImages'  alt=''/>
                  </Box>
                </Box>
              </Box>

              {largeView && <Box className="CloseLargeView" onClick={() => setLargeView(false)}>
                  <img src={CloseIcon} className='closeIcon' alt="Close LargeView" />
              </Box>}
              
            </Box>

            {!largeView && <Box className="LargeView" onClick={() => setLargeView(true)}>
              <img src={LensIcon} className='lensIcon' alt="LargeView" />
            </Box>}

          </Box>

        </Box>

      </Box>
    </>
  )
}
