import React, { useState, useEffect } from 'react';
import ImageCropper from "../../Components/ImageCropper";
import FileInput from "../../Components/FileInput";
import "./style.css";
import { Box } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ContentCutIcon from '@mui/icons-material/ContentCut';

export default function UploadPopup({showPopup, setShowPopup, uploadType, getFrontImage, getBackImage, setCustomCover}) {

  const [currentUpload, setCurrentUpload] = useState(false);
  const [image, setImage] = useState(getFrontImage);
  const [uploadStage, setUploadStage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");

  const [bleedLine, setBleedLine] = useState(false);
  const [cropStart, setCropStart] = useState(false);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    console.log("useEffect=>",currentUpload, uploadType, getFrontImage, getBackImage)
    // Set the parent state in the child component's state
    setCurrentUpload(uploadType);
    if(uploadType=="Front"){
      setImage(getFrontImage);
      setUploadStage(getFrontImage!=""? "crop-img":"choose-img");
      setImgAfterCrop("");
      setCropStart(false);
    }else{
      setImage(getBackImage);
      setUploadStage(getBackImage!=""? "crop-img":"choose-img");
      setImgAfterCrop("");
      setCropStart(false);
    }

    
  }, [getFrontImage, getBackImage, uploadType]);
  

  const handleUploadPopup = ()=>{ 
    setShowPopup(true);
  }
  const handleCustomCover = ()=>{
    setShowPopup(false);
  }
  const handleUploadCancel = ()=>{ 
    onCropCancel(); 
    setCustomCover(currentUpload,"");
    setShowPopup(false);
  }


  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setUploadStage("crop-img");
    
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCropped) => {
    let imageObj = new Image();
    imageObj.src = image;
    imageObj.onload = function () {
      const canvas = document.createElement("canvas");
  
      // Calculate scale factors
      const scaleX = imageObj.naturalWidth / imageObj.width;
      const scaleY = imageObj.naturalHeight / imageObj.height;
  
      // Determine dimensions for the cropped image
      const minWidth = 800;
      const minHeight = 960;
      const maxWidth = 2412;
      const maxHeight = 3132;
  
      let cropWidth = imgCropped.width * scaleX;
      let cropHeight = imgCropped.height * scaleY;
  
      // If the cropped dimensions are less than the minimum, adjust them
      if (cropWidth < minWidth) {
        cropWidth = minWidth;
      }
      if (cropHeight < minHeight) {
        cropHeight = minHeight;
      }
  
      // If the cropped dimensions exceed the maximum, adjust them
      if (cropWidth > maxWidth) {
        cropWidth = maxWidth;
      }
      if (cropHeight > maxHeight) {
        cropHeight = maxHeight;
      }
  
      // Set canvas dimensions
      canvas.width = cropWidth;
      canvas.height = cropHeight;
  
      const context = canvas.getContext('2d');
  
      // Calculate the position and size of the cropped region
      const cropX = Math.max(0, imgCropped.x * scaleX);
      const cropY = Math.max(0, imgCropped.y * scaleY);
      const drawWidth = Math.min(cropWidth, imageObj.naturalWidth - cropX);
      const drawHeight = Math.min(cropHeight, imageObj.naturalHeight - cropY);
  
      context.drawImage(
        imageObj,
        cropX,
        cropY,
        drawWidth,
        drawHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
  
      const dataURL = canvas.toDataURL("image/jpeg");
      setImgAfterCrop(dataURL);
      setUploadStage("img-cropped");
    };
  };
  /*const onCropDone = (imgCropped) => {

    let imageObj = new Image();
    imageObj.src = image;
    imageObj.onload = function () {
      const canvas = document.createElement("canvas");

      const scaleX = imageObj.naturalWidth / imageObj.width;
      const scaleY = imageObj.naturalHeight / imageObj.height;
      canvas.width = imgCropped.width * scaleX;
      canvas.height = imgCropped.height * scaleY;
      const context = canvas.getContext('2d');

      console.log("imageObj natural=>", imageObj.naturalWidth, imageObj.naturalHeight)
      console.log("imageObj=>", imageObj.width, imageObj.height)
      console.log("imgCropped=>", imgCropped.width, imgCropped.height)
      console.log("canvas=>", scaleX, scaleY, canvas.width, canvas.height)
      console.log("ImgDraw=>", imgCropped.x * scaleX, imgCropped.y * scaleY, imgCropped.width * scaleX, imgCropped.height * scaleY)

      context.drawImage(
        imageObj,
        imgCropped.x * scaleX,
        imgCropped.y * scaleY,
        imgCropped.width * scaleX,
        imgCropped.height * scaleY,
        0,
        0,
        imgCropped.width * scaleX,
        imgCropped.height * scaleY
      );

      const dataURL = canvas.toDataURL("image/jpeg");
      setImgAfterCrop(dataURL);
      setUploadStage("img-cropped");
    };
  };*/

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setUploadStage("choose-img");
    setImage("");
    
  };


  const handleSave = () =>{
    if(currentUpload === "Front" ){
      setCustomCover(currentUpload,imgAfterCrop);
    }else{
      setCustomCover(currentUpload,imgAfterCrop);
    }
  }

  const handleCrop = () =>{
    setCropStart(true);
  }
  

  return (
    <>
    { showPopup && ( 
      <Box className='coverPopUp UploadPopup'>
        <CancelOutlinedIcon color="action" onClick={() => setShowPopup(false)} className="coverPopUpClose" />

        <Box className="">
          {currentUpload=="Front" ? 'Front Cover':'Back Cover'}
        </Box>  
        <Box className="coverPImageBox">
        
          {uploadStage === "choose-img" ? (
              <>
                <Box className="uploadFrontElement">
                  <FileInput onImageSelected={onImageSelected} btnTitle="Choose Image" />
                </Box>
              </>
              
            ) : uploadStage === "crop-img" ? (
              <ImageCropper
                image={image}
                onCropDone={onCropDone}
                onCropCancel={onCropCancel}
                bleedLine={bleedLine}
                cropStart={cropStart}
              />
            ) : (
              <Box>
              <Box>
                  <img src={imgAfterCrop} className="cropped-img" alt="" />
                </Box>
              </Box>
            )
          }


          <Box className='btnContainer'>

            <Box className="">
              {imgAfterCrop=="" && <button
                  onClick={() => {
                    setBleedLine(!bleedLine);
                  }}
                  className={`UploadBtn bleed ${(bleedLine)? 'active':''}`} 
                >
                <Box  className='inner'>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.45399 2.30644V0.441406H3.6319V2.30644H12.4663V0.441406H13.6442V2.30644H16V3.48435H13.6442V12.3187H16V13.4966H13.6442V15.558H12.4663V13.6929C12.4663 13.5845 12.3784 13.4966 12.2699 13.4966H3.6319V15.558H2.45399V13.4966H0V12.3187H2.45399V3.48435H0V2.30644H2.45399ZM3.6319 3.48435V12.3187H12.4663V3.48435H3.6319Z" fill="#E7E7EE"></path></svg>
                </Box>
                Bleed Line
              </button>}
            

                {imgAfterCrop=="" && <button
                  onClick={() => {
                    handleCrop();
                  }}
                  className={`UploadBtn active`} 
                >
                <Box className='inner'>
                <ContentCutIcon alt="CropIcon" />
                </Box>
                Crop
              </button>}
              {imgAfterCrop!="" && <button
                  onClick={() => {
                    handleSave();
                  }}
                  className={`UploadBtn active`} 
                >
                <Box className='inner'>
                <SaveOutlinedIcon alt="SaveIcon" />
                </Box>
                Save
              </button>}
              <button
                  onClick={() => handleUploadCancel()}
                  className={`UploadBtn active`} 
                >
                <Box  className='inner'>
                <CloseOutlinedIcon alt="CancelIcon" />
                </Box>
                Cancel
              </button>
              
              
              {/*<button
                  onClick={}
                  className={`UploadBtn active `} 
                >
                <Box  className='inner'>
                <CancelOutlinedIcon alt="CloseIcon" />
                </Box>
                Close
                </button>*/}
            </Box>
          </Box>

        </Box>
          
      </Box>
      
    )}
    </>
  )

}