import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Box, TextField, Typography } from '@mui/material'
import "./style.css"
import config from '../../../config';

import GiftTabs from '../../../Components/Tabs/GiftTabs.jsx';

const BACKEND_URL = config.BACKEND_URL;



export default function AccessoryKit({updateTabPage}) {
  const navigate =  useNavigate();
  const location = useLocation();

  var CartData = sessionStorage.getItem("Cart"); 

  const userSession = sessionStorage.getItem("User");
  const apiToken = sessionStorage.getItem("Token");

  const currentRoute = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const act = searchParams.get("act");

  const [myUser,setMyUser] = useState("");
  const [isLoggedIn,setIsLoggedIn] = useState(false);




  useEffect(() => {
    if(userSession!=undefined ){
      const userSessionData = JSON.parse(userSession);
      setMyUser(userSessionData);
      setIsLoggedIn(true);
    }

    /*const fetchGiftCardImages = async () => {
      await axios.get(`${BACKEND_URL}/api/V1/giftcards/`)
        .then( response => {
          const imageArray = response.data.result.giftcards.map(giftcard => giftcard.image);
          setGiftCardImages(imageArray);
         
        })
        .catch(error => {
          console.error('Error fetching gift card data:', error);
        });
    }
    fetchGiftCardImages();

    if(act=="addToCart"){
      addToCart()
    }*/

  },[]);



  /*const addToCart = () =>{

    var GiftCardData = sessionStorage.getItem("GiftCard"); 
    if(GiftCardData!=null){
      const ApiHeaders= {
        'Authorization': `Bearer ${apiToken}`,
        'Content-Type': 'application/json', // Include this header if needed
      }
  
      const postData = JSON.parse(GiftCardData);
      //console.log("postData=>",postData, ApiHeaders)
      
      axios.post(`${BACKEND_URL}/api/V1/addGiftCard`, postData,{ headers: ApiHeaders } )
        .then(response => {
          if(response.data.status){
            var cart_data={}; 
            //console.log("CartData2=>",CartData)
            if(CartData===null){
              CartData = {CartID:response.data.result.CartID}
            }else{
              //console.log(CartData,response.data.result.CartID )
              if(typeof CartData=="string"){
                CartData = JSON.parse(CartData)
              }
              CartData.CartID = response.data.result.CartID;
            }
            sessionStorage.setItem("Cart", JSON.stringify(CartData))  
            sessionStorage.removeItem("GiftCard") 
            navigate("/cart")
          }else{
            toast(response.messsage,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
  
          }
          
        })
        .catch(error => {
          console.error('Error fetching layout data:', error);
          toast(error,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
          });
        });
 
    }
  }*/

 

  return (
    <>
      <Box className="PageInnerBox">

        <Box className="LeftPanelBox">

          <GiftTabs selectedTab="AccessoryKit" updateTab={updateTabPage} />
           
        </Box>

        <Box className="RightPanelBox">

          <Box className="RightHeader">
            <Typography className='RightHeaderText'> Coming Fall 2024 </Typography>
          </Box>
       

        </Box>

      </Box>

    </>
  )
}
