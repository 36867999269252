import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Modal } from '@mui/material';
import { ButtonPrimary } from "../../Components/Buttons"


const OrderDetailModal = ({ order, onClose  }) => {


    const formatDate = (paymentDate) => {
        // Create a Date object from the payment date string
        const date = new Date(paymentDate);
      
        // Extract individual date and time components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
      
        // Convert hours to 12-hour format and determine AM/PM
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
      
        // Combine date and time components into the desired format
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}${amPm}`;
      
        return formattedDate;
      };

    return (
      order!=null ? <Modal
      open={order !== null}
      onClose={() => onClose(null)}
      aria-labelledby="order-detail-modal"
    >
      <Box className="card" >
        <Typography className="PopInfoClose" onClick={()=>onClose(null)}>X</Typography>
        <div className="row2">
          <div className="col-md-6">
              <h5>Order #{order.id}</h5>
              
              <div className="mb-3">
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label">Order Code</label>
                      </div>
                      <div className="col-md-9">{order.code}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label">Order Date</label>
                      </div>
                      <div className="col-md-9">
                      { formatDate(order.created_at)}
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="order.total">Order Amount</label>
                      </div>
                      <div className="col-md-9">{'$'+order.total}</div>
                  </div>
                  
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="order.total">Payment Status</label>
                      </div>
                      <div className="col-md-9">{order.payment_status==1? 'Paid':'Unpaid'}</div>
                  </div>
                 
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label">Payment Type</label>
                      </div>
                      <div className="col-md-9">{order.payment_type}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label">Payment Date</label>
                      </div>
                      <div className="col-md-9">
                      { formatDate(order.payment_date)}
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label">Payment Id</label>
                      </div>
                      <div className="col-md-9">{order.payment_id}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label">Order Status</label>
                      </div>
                      <div className="col-md-9">{order.status_title}</div>
                  </div>
              </div>
              
          </div>
          <div className="col-md-6">
              <h5>Shipping Detail</h5>
              <div className="mb-3">
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_name">Name</label>
                      </div>
                      <div className="col-md-9">{order.shipping_name}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_email">Email</label>
                      </div>
                      <div className="col-md-9">{order.shipping_email}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_phone">Phone</label>
                      </div>
                      <div className="col-md-9">{order.shipping_phone}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_houseno">House</label>
                      </div>
                      <div className="col-md-9">{order.shipping_houseno}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_street">Street</label>
                      </div>
                      <div className="col-md-9">{order.shipping_street}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_city">City</label>
                      </div>
                      <div className="col-md-9">{order.shipping_city}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_state">State</label>
                      </div>
                      <div className="col-md-9">{order.shipping_state}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_zipcode">Zipcode</label>
                      </div>
                      <div className="col-md-9">{order.shipping_zipcode}</div>
                  </div>
                  <div className="row">
                      <div className="col-md-3">
                          <label className="form-label" for="shipping_country">Country</label>
                      </div>
                      <div className="col-md-9">{order.shipping_country}</div>
                  </div>

              </div>

          </div>
        </div>
        <div className="closebtn">
            <ButtonPrimary buttonText="Close" handelClick={()=>onClose(null)}  />
        </div>
      
      </Box>
    </Modal>:''
    )
  }

  export default OrderDetailModal;