import React  from 'react';
import { Box, Typography } from '@mui/material';
import {useNavigate } from 'react-router-dom';
import "./style.css"

import HomeCoverItem from "../../../Components/CoverItemHome"
import HomeVideo from "../../../Assets/videos/Website_video.mp4";


export default function GetStarted({coverList}) {

  const navigate =  useNavigate();

  const handleSelectedCover = (id, front_img, back_img)=>{ 
    sessionStorage.setItem("Cover", JSON.stringify({
      CoverType:'predesign', 
      CoverId: id, 
      FrontImage: front_img,
      BackImage: back_img
    }));
    navigate("/cover#FirstPage")
  }


  return (
    <>
      {/*<Box className="getVideoContainer Home">
        <Box className="getVideoHeader">
          <Typography>4 Steps to Create Your Custom Planner</Typography>
        </Box>
        <Box className="homevideoBox">
          <video controls width="600" style={{width:'100%'}}>
            <source src={HomeVideo} type="video/mp4" />
          </video>
        </Box>
      </Box>*/}
      <Box className="getStartedContainer Home">
        <Box className="getStartedHeader">
          <Typography>How to Get Started</Typography>
        </Box>
        <Typography className="getStartedHeaderSmall">Choose a Cover</Typography>
        <Box className="coverCardBox">
        {coverList.map((cover, coverIndex) => (
            <>
            <HomeCoverItem key={`${coverIndex}`} coverData={cover} handleSelectedCover={handleSelectedCover} />
          </>
        ))}
        </Box>
        <Box className="button seecovers" onClick={()=>navigate("/cover")}>
          <Typography>See More Covers</Typography>
        </Box>
      </Box>
    </>
  )
}
