import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import "./style.css";

//Component 
import NavBar from '../NavBar';
import Footer from '../Footer';
import PlusIcon from '../../Assets/images/plus.png';

// pages
import Calendars from "./Calendars";
import VisionAndGoals from "./VisionAndGoals"
import Reflection from "./Reflection";
import Habits from "./Habits"
import Health from "./Health"
import Career from "./Career"
import Family from "./Family"
import Others from "./Others"
import Partners from "./Partners"

export default function Addins() {
  const [tab, setTab] = useState("VisionAndGoal");
  const [priceBox, setPriceBox] = useState(1);
  const [menuPopInfo, setMenuPopInfo] = useState(false);

  useEffect( () => {

    if (!localStorage.getItem('addinspopup')) {
      setMenuPopInfo(true);
    }

  },[]);

  const handlePricebox =()=>{
    setPriceBox(priceBox+1);
  }

  const closePopup = () =>{
    localStorage.setItem('addinspopup', 'true');
    setMenuPopInfo(false)
  }

  const handleTab =(tabname)=>{
    setTab(tabname);
  }


  return (
    <>
      <NavBar updatePriceBox={priceBox}  />
      <Box className="PageContainer">
         
          <Box className="PageBox">
            {tab === "Calendars" ? <Calendars  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "VisionAndGoal" ? <VisionAndGoals  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Reflection" ? <Reflection  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Habits" ? <Habits  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Health" ? <Health  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Career" ? <Career  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Family" ? <Family  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Others" ? <Others  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
            {tab === "Partners" ? <Partners  updatePriceBox={handlePricebox} updateTabPage={handleTab} /> : null}
          </Box>

          <Box className={menuPopInfo? 'PopInfo arrow-top open':'PopInfo'}>
            <Box className="PopInfo-wrapper">
              <Typography className="PopInfoClose" onClick={closePopup}>X</Typography>
              <Typography className="PopInfoText">
                  <span>Add-In Options</span>
                  Don’t miss these add-in options! The submenu bar above has add-ins like habit trackers, meal planners, fitness logs, child info sheets, meeting notes, brainstorm sheets, monthly reflection pages, rituals, and so much more.
              </Typography>
              <Typography className="PopInfoText">
                  <span>How to Include Add-Ins</span>
                  See an add-in you want? Hit the <img src={PlusIcon} alt="plus icon" className="plusicon" /> icon on the top right corner of the thumbnail in the design bar. Be sure to select from the dropdown where you’d like your add-in (e.g., the front of your planner, between each week, between each month, or at the end of your planner).
              </Typography>
              <Typography className="PopInfoText">
                We do encourage people to select a personal vision and goals page for the front of their planner, but it is your planner, and you create it how you like. You do you! 😎
              </Typography>

              <Box className="PopInfoBtnClose button" onClick={closePopup}><p>Close</p></Box>
            </Box>
          </Box>
        
        </Box>
      <Footer />
    </>
  )
}
