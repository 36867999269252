import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import "./style.css";

export default function DateTabs({selectedTab, updateTab}) {

  const [tab, setTab] = useState("");
  
  useEffect(() => {
    setTab(selectedTab)
  },[]);

  const handleTab = (tabname) =>{
    setTab(tabname);
    updateTab(tabname);
  }

  return (
    <>
      <Box className="LeftHeader">
        <Typography className='LeftTitle'>Choose Dates And Holidays</Typography>
      </Box>
      <Box className="pagetab-list twobtn">
        <Box className={tab === "Dates" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Dates")} >
          <Typography>Dates</Typography>
        </Box>
        <Box className={tab === "Holidays" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Holidays")} >
          <Typography>Holidays</Typography>
        </Box>
      </Box>
    </>
  )
}
