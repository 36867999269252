import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import "./style.css";

export default function AddinTabs({selectedTab, updateTab}) {

  const [tab, setTab] = useState("");
  
  useEffect(() => {
    setTab(selectedTab)
  },[]);

  const handleTab = (tabname) =>{
    setTab(tabname);
    updateTab(tabname);
  }

  return (
    <>
      <Box className="LeftHeader">
        <Typography className='LeftTitle'>Addins</Typography>
        <Typography className='LeftSubText'>Choose a category, and then select add-in template(s).</Typography>
      </Box>
    
      <Box className="pagetab-list">
        <Box className={tab === "Calendars" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Calendars")}>
          <Typography>Calendars</Typography>
        </Box>
        <Box className={tab === "VisionAndGoal" ? "pagetab active" : "pagetab"} onClick={() => handleTab("VisionAndGoal")}>
          <Typography>Vision/Goals</Typography>
        </Box>
        <Box className={tab === "Reflection" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Reflection")}>
          <Typography>Reflection</Typography>
        </Box>
        
        <Box className={tab === "Habits" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Habits")}>
          <Typography>Habits</Typography>
        </Box>
        <Box className={tab === "Health" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Health")}>
          <Typography>Health</Typography>
        </Box>
        <Box className={tab === "Career" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Career")}>
          <Typography>Career</Typography>
        </Box>
        <Box className={tab === "Family" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Family")}>
          <Typography>Family</Typography>
        </Box>
        
        <Box className={tab === "Others" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Others")}>
          <Typography>Other</Typography>
        </Box>
        <Box className={tab === "Partners" ? "pagetab active" : "pagetab"} onClick={() => handleTab("Partners")}>
          <Typography>Partners</Typography>
        </Box>
      </Box>
      <Box className="LeftHeader">
        <Typography className='LeftSubText'>Choose your template(s) below. Use the “plus” button to add a template and select its location in the planner from the dropdown.</Typography>
      </Box>
    </>
  )
}
