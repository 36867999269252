import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




import axios from 'axios';
import config from '../../../config';

//component
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import MyAccountMenu from "../../../Components/MyAccountMenu";
import CoverBookImg from "../../../Assets/images/coverBook.png";
import OrderDetailModal from "../../../Components/OrderDetailModal";


export default function MyAccount() {
  const BACKEND_URL = config.BACKEND_URL;
  const navigate = useNavigate()

  const userSession = sessionStorage.getItem("User");
  const apiToken = sessionStorage.getItem("Token");
  const ApiHeaders= {
    'Authorization': `Bearer ${apiToken}`,
    'Content-Type': 'application/json', // Include this header if needed
  }

  const [activeTab, setActiveTab] = useState("MY-ACCOUNT")
  const [sideMenu, setSideMenu] = useState(false)
  const [userData, setUserData] = useState([])
  const [orderData, setOrderData] = useState([])
  const [saveData, setSaveData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    
    if(userSession!==undefined && userSession!==null){
      axios.get(`${BACKEND_URL}/api/V1/myaccount/`, { headers: ApiHeaders})
          .then(response => {
            const { User, Orders, SaveData } = response.data.result;
            setUserData(User);
            setOrderData(Orders);
            setSaveData(SaveData);
          })
          .catch(error => {
            console.error('Error fetching layout data:', error);
          });
      }else{
        navigate("/");
      }
    
  },[]);


  const handleLoadDiary = (id) =>{
    const saveDiaryItem = saveData.find(item => item.id === id);
    const diaryData = JSON.parse(saveDiaryItem.diarydata);
    if(diaryData!==null){

      sessionStorage.setItem("SaveDiaryId", id); 

      // Parse each inner JSON string
      var Cover = (diaryData.Cover);
      var FirstPage = (diaryData.FirstPage);
      var Layout = (diaryData.Layout);
      var Calendar = (diaryData.Calendar);
      var Addins = (diaryData.Addins);
      var Dates = (diaryData.Dates);
      console.log("diaryData=>",diaryData)

      Cover!=null && sessionStorage.setItem("Cover", Cover); 
      FirstPage!=null && sessionStorage.setItem("FirstPage", FirstPage); 
      Layout!=null && sessionStorage.setItem("Layout", Layout); 
      Calendar!=null && sessionStorage.setItem("Calendar", Calendar); 
      Addins!=null && sessionStorage.setItem("Addins", Addins); 
      Dates!=null && sessionStorage.setItem("Dates", Dates); 

      toast("Diary data load successfully",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () =>  navigate('/review')
        });
    }
  }

   const viewOrder = (orderId) => {
    // Find the order with the given orderId
    const order = orderData.find(order => order.id === orderId);
    setSelectedOrder(order);
  };

  const closeOrderDetailModal = () => {
    setSelectedOrder(null);
  };


  return (
    <>
      <NavBar />
      <Box className="myAccountContainer">

        <Box className="myAccountHeader">
          <Typography className="myAccountHeaderText">
            Hello, {userData.first_name} {userData.last_name!=null? userData.last_name:''}
          </Typography>
          <Box className="myAccountHeaderNavigation">
            <Typography mr={1}>HOME / </Typography>
            <Typography mr={1}>ACCOUNT / </Typography>
            <Typography mr={1}>MY ACCOUNT </Typography>
          </Box>
        </Box>

        <Box className="myAccountInfoBox">
          <MyAccountMenu  statusMenu={sideMenu} />

          <Box className="myAccountDetailsBox" sx={{ left: sideMenu ? "150px" : "0px" }}>

            {/* my account info  */}
            <Box className="myAccountTab">
              <SwapHorizontalCircleOutlinedIcon onClick={() => setSideMenu(!sideMenu)} className="sideMenuIcon" />
              <Box className="myAccountTabItem">
                <Typography className="myAccountTabHeader">Recent Projects</Typography>
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>PROJECT</TableCell>
                      <TableCell >PROJECT NAME</TableCell>
                      <TableCell className="deskbtn" >ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {saveData.length>0 && saveData.map((item)=>{
                    const diaryData = JSON.parse(item.diarydata);
                    const coverData = diaryData.Cover ? JSON.parse(diaryData.Cover):'';
                    const firstPageData = diaryData.FirstPage ? JSON.parse(diaryData.FirstPage):'';
                
                    return (
                      <TableRow
                        key={item.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {coverData?.CoverType === 'predesign' && (
                            <img src={coverData?.FrontImage || CoverBookImg} alt="Front Cover" width="50" />
                          )}
                          <Typography className="load-design pointer hideDesktop"  onClick={()=>handleLoadDiary(item.id)}>Load design</Typography>
                        </TableCell>
                        <TableCell >
                          {firstPageData?.Name}
                          <Typography className="load-design pointer hideDesktop"  onClick={()=>handleLoadDiary(item.id)}>Load design</Typography>
                        </TableCell>
                        <TableCell className="deskbtn"><Typography className="load-design pointer"  onClick={()=>handleLoadDiary(item.id)}>Load design</Typography></TableCell>
                      </TableRow>
                    )}
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
          
              <Box className="myAccountTabItem myAccountTabSubYSpacing2">
                <Typography className="myAccountTabHeader">Recent Orders</Typography>
                <Typography className="myAccountTabLinkText" onClick={() => {
                  navigate("/myorders")
                }}>VIEW ALL</Typography>
                {/*<Typography className="myAccountTabLinkText">OPEN TICKET</Typography>*/}

              </Box>
              
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ORDER NUMBER</TableCell>
                      <TableCell >DATE</TableCell>
                      <TableCell >SHIP TO</TableCell>
                      <TableCell >ORDER TOTAL</TableCell>
                      <TableCell >STATUS</TableCell>
                      <TableCell >ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderData.map((order)=>(
                      <TableRow
                        key={order.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">{order.code}</TableCell>
                        <TableCell >{order.created_at}</TableCell>
                        <TableCell >{order.shipping_name}</TableCell>
                        <TableCell >${order.total}</TableCell>
                        <TableCell >{order.status_title}</TableCell>
                        <TableCell><Typography className="pointer"  onClick={()=>viewOrder(order.id)} >details</Typography></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              

              

              <Box className="myAccountTabItem">
                <Typography className="myAccountTabHeader">Account Information</Typography>
              </Box>

              <Box className="myAccountTabItem2">
                <Typography className="myAccountTabSubHeaderEmail">CONTACT INFORMATION</Typography>
                <Typography className="myAccountTabSubHeaderEmail">{userData.email}</Typography>
                <Typography className="myAccountTabSubHeaderEmail">{userData.phone}</Typography>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography mr={11} className="myAccountTabLinkText" onClick={() => {
                  navigate("/edit-profile")
                }}>EDIT</Typography>
                  <Typography className="myAccountTabLinkText" onClick={() => {
                  navigate("/change-password")
                }}>CHANGE PASSWORD</Typography>
                </Box>

              </Box>

              <Box className="myAccountTabItem myAccountTabSubYSpacing2">
                <Typography className="myAccountTabHeader">Address Book</Typography>
                <Typography className="myAccountTabLinkText" onClick={()=>navigate('/addressbook')}>MANAGE ADDRESS</Typography>


              </Box>

              <Box className="myAccountTabItem">
                <Typography className="myAccountTabHeader">0</Typography>

              </Box>



            </Box>


          </Box>

        </Box>





      </Box >
      <ToastContainer autoClose={false} draggable={false} />
      <OrderDetailModal order={selectedOrder} onClose={closeOrderDetailModal} />  
      <Footer />
    </>
  );
}
