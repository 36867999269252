import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import "./style.css";
import { useNavigate } from "react-router-dom";
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Modal } from '@mui/material';


import { ButtonPrimary } from "../../../Components/Buttons"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


import axios from 'axios';
import config from '../../../config';

//component
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import MyAccountMenu from "../../../Components/MyAccountMenu";
import OrderDetailModal from "../../../Components/OrderDetailModal";




export default function MyAccount() {
  const BACKEND_URL = config.BACKEND_URL;
  const navigate = useNavigate()

  const userSession = sessionStorage.getItem("User");
  const apiToken = sessionStorage.getItem("Token");
  const ApiHeaders= {
    'Authorization': `Bearer ${apiToken}`,
    'Content-Type': 'application/json', // Include this header if needed
  }

  const [activeTab, setActiveTab] = useState("MY-ACCOUNT")
  const [sideMenu, setSideMenu] = useState(false)
  const [userData, setUserData] = useState([])
  const [orderData, setOrderData] = useState([])
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    
    if(userSession!==undefined && userSession!==null){
      axios.get(`${BACKEND_URL}/api/V1/myorders/`, { headers: ApiHeaders})
          .then(response => {
            const { User, Orders } = response.data.result;
            setUserData(User);
            setOrderData(Orders);
          })
          .catch(error => {
            console.error('Error fetching layout data:', error);
          });
      }else{
        navigate("/");
      }
    
  },[]);


  const viewOrder = (orderId) => {
    // Find the order with the given orderId
    const order = orderData.find(order => order.id === orderId);
    setSelectedOrder(order);
  };

  const closeOrderDetailModal = () => {
    setSelectedOrder(null);
  };

  

  
  

  return (
    <>
      <NavBar />
      <Box className="myAccountContainer">

        <Box className="myAccountHeader">
          <Typography className="myAccountHeaderText">
            Hello, {userData.first_name+' '+userData.last_name}
          </Typography>
          <Box className="myAccountHeaderNavigation">
            <Typography mr={1}>HOME / </Typography>
            <Typography mr={1}>ACCOUNT / </Typography>
            <Typography mr={1}>MY ORDERS </Typography>
          </Box>
        </Box>

        <Box className="myAccountInfoBox">
          <MyAccountMenu statusMenu={sideMenu} />

          <Box className="myAccountDetailsBox" sx={{ left: sideMenu ? "150px" : "0px" }}>

            {/* my account info  */}
            <Box className="myAccountTab">
              <SwapHorizontalCircleOutlinedIcon onClick={() => setSideMenu(!sideMenu)} className="sideMenuIcon" />
  
              <Box className="myAccountTabItem myAccountTabSubYSpacing2">
                <Typography className="myAccountTabHeader">Recent Orders</Typography>
                <Typography className="myAccountTabLinkText" onClick={handleClickOpen}>OPEN TICKET</Typography>

              </Box>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ORDER NUMBER</TableCell>
                      <TableCell >DATE</TableCell>
                      <TableCell >SHIP TO</TableCell>
                      <TableCell >ORDER TOTAL</TableCell>
                      <TableCell >STATUS</TableCell>
                      <TableCell >ACTION</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderData.map((order)=>(
                      <TableRow
                        key={order.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">{order.code}</TableCell>
                        <TableCell >{order.created_at}</TableCell>
                        <TableCell >{order.shipping_name}</TableCell>
                        <TableCell >${order.total}</TableCell>
                        <TableCell >{order.status_title}</TableCell>
                        <TableCell  onClick={()=>viewOrder(order.id)} className="pointer">details</TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              
            </Box>

          </Box>

        </Box>

      </Box >
      <Footer />
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Open Ticket"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          We're here to help. Please email support@becomingyourbest.com or call 888-690-8764 for the fastest assistance. We'll get right back with you.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box sx={{width:'100px', margin:'0 auto'}}>
          <ButtonPrimary buttonText="Close" handelClick={handleClose}   />
          </Box>
        </DialogActions>
      </Dialog>

      <OrderDetailModal order={selectedOrder} onClose={closeOrderDetailModal} />   
    </>
  );
}
