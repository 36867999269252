import React, { useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';

import "./style.css";
import axios from 'axios';
import config from '../../config';
import { toast, ToastContainer } from 'react-toastify';


//component
import NavBar from '../NavBar';
import Footer from '../Footer';
import EmailIcon from "../../Assets/images/EmailIcon.svg"

export default function ContactUs() {

  const BACKEND_URL = config.BACKEND_URL;

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };


  
  /* ------------- Contact Code -------------------*/
  
  const [contactSubmited, SetContactSubmited] = useState(false)
  const [contactLog, SetContactLog] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleContactParameter = (e) => {
    const { name, value } = e.target;

    setErrorMessage('');
    setMessage('');
    
    SetContactLog({
      ...contactLog,
      [name]: value,
    });
  };

  const handleContactSubmit = async (e) => {

    SetContactSubmited(true);
    //for not send data in url
    if(contactLog.name!=="" && contactLog.email!=="" && contactLog.subject!=="" && contactLog.message!=="" && validateEmail(contactLog.email))
    {
      axios.post(
        `${BACKEND_URL}/api/V1/contact`,
        contactLog
      ).then(response => {
        if(response.data.status){
          SetContactLog({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
          
          SetContactSubmited(false);
          toast(response.data.result.message,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          });
          console.log(contactLog)
         
        }else{
          setErrorMessage(response.data.errors);
        }


      }).catch(error => {
        console.error('Error fetching data:', error);
        setErrorMessage('Unable to Contact');
      });
      
    }
    
  };
  /* ------------- Contact Code -------------------*/



  return (
    <>
      <NavBar />
      <Box className="contactContainer">
        <Box className="contactBox">
          <Box className="contactTopLogo">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="34" viewBox="0 0 40 34" fill="none">
            <defs>
                <filter x="-0.7%" y="-0.9%" width="101.6%" height="102.8%" filterUnits="objectBoundingBox" id="filter-1">
                    <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="2.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="contact" transform="translate(-619.000000, -184.000000)" fill-rule="nonzero">
                    <g filter="url(#filter-1)" transform="translate(30.000000, 147.000000)" id="icon">
                        <g transform="translate(594.000000, 39.000000)">
                            <path d="M27.7762238,22.4055944 C28.1439207,22.4055944 28.4475524,22.1019627 28.4475524,21.7342657 L28.4475524,2.26573427 C28.4475524,1.89803731 28.1439207,1.59440559 27.7762238,1.59440559 L2.26573427,1.59440559 C1.89803731,1.59440559 1.59440559,1.89803731 1.59440559,2.26573427 L1.59440559,21.7342657 C1.59440559,22.1019627 1.89803731,22.4055944 2.26573427,22.4055944 L27.7762238,22.4055944 Z M27.7762238,23.7482517 L2.26573427,23.7482517 C1.15650814,23.7482517 0.251748252,22.8434919 0.251748252,21.7342657 L0.251748252,2.26573427 C0.251748252,1.15650814 1.15650814,0.251748252 2.26573427,0.251748252 L27.7762238,0.251748252 C28.8854499,0.251748252 29.7902098,1.15650814 29.7902098,2.26573427 L29.7902098,21.7342657 C29.7902098,22.8434919 28.8854499,23.7482517 27.7762238,23.7482517 Z" id="Shape" fill="#BC9448"></path>
                            <path d="M20.3916084,5.62237762 L20.3916084,8.97902098 L24.4195804,8.97902098 L24.4195804,5.62237762 L20.3916084,5.62237762 Z M19.7202797,4.27972028 L25.0909091,4.27972028 C25.4616737,4.27972028 25.7622378,4.58028436 25.7622378,4.95104895 L25.7622378,9.65034965 C25.7622378,10.0211142 25.4616737,10.3216783 25.0909091,10.3216783 L19.7202797,10.3216783 C19.3495151,10.3216783 19.048951,10.0211142 19.048951,9.65034965 L19.048951,4.95104895 C19.048951,4.58028436 19.3495151,4.27972028 19.7202797,4.27972028 Z" id="Rectangle-path" fill="#000000"></path>
                            <polygon id="Shape" fill="#000000" points="4.27972028 14.3496503 9.65034965 14.3496503 9.65034965 13.006993 4.27972028 13.006993"></polygon>
                            <polygon id="Shape" fill="#000000" points="4.27972028 17.034965 14.3496503 17.034965 14.3496503 15.6923077 4.27972028 15.6923077"></polygon>
                            <polygon id="Shape" fill="#000000" points="4.27972028 19.7202797 14.3496503 19.7202797 14.3496503 18.3776224 4.27972028 18.3776224"></polygon>
                        </g>
                    </g>
                </g>
            </g>
          </svg>
          </Box>

          <Typography className='contactHeader'>Contact Us</Typography>
          <Typography className='contactHeaderSubtotal'>Form</Typography>
          <Typography className='contactSubHeader'>Get in touch with us for any questions, concerns, or if you just want to say hello. We welcome all messages. Talk to you soon!</Typography>
          <Box  className='contactForm'>
              {message ? <p className="text-green-600">*{message}</p>:"" }
              {errorMessage!=="" ? <p className="text-red-600">{errorMessage}</p>:"" }

              <Box mb={1} className="emailInputBox">
                <TextField className='emailInput' name='name' placeholder='Full Name' value={contactLog.name}  onChange={handleContactParameter} required/>
              </Box>
             

              <Box mb={1} className="emailInputBox">
                <TextField className='emailInput' name='email' type='email' placeholder='Email' value={contactLog.email}  onChange={handleContactParameter} required/>
                <img className='emailIcon' src={EmailIcon} alt='EmailIcon' />
              </Box>
              { !validateEmail(contactLog.email) & contactLog.email!==""  & contactSubmited ? <p className="text-red-600">*please enter valid Email</p>:"" }

              <Box mb={1} className="emailInputBox">
                <TextField className='emailInput' name='subject' placeholder='Subject' value={contactLog.subject}  onChange={handleContactParameter} required/>
              </Box>
              <Box mb={1} className="textAreaInputBox">
                <TextField className='textAreaInput' multiline
    rows={4} name='message' placeholder='Message' value={contactLog.message}  onChange={handleContactParameter} required/>
              </Box>
              
              <button  className="button pointer" onClick={handleContactSubmit} >
                <Typography>SEND</Typography>
              </button>
          </Box>
         
          <Typography className='contactSubHeader2' ml={1}>You can also get in touch via email: <span>support@becomingyourbest.com</span></Typography>

        </Box>
      </Box>
      <Footer extraClass="contactfooter"/>
      <ToastContainer autoClose={false} draggable={false} />
    </>
  )
}
