import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import config from '../../config';
import 'react-toastify/dist/ReactToastify.css'
import "../layout.css";
import "./style.css";


// icons
import UpArrow from "../../Assets/images/UpArrow.svg";
import DownArrow from "../../Assets/images/DownArrow.svg";

//Components
import NavBar from '../NavBar';
import Footer from '../Footer';
import DateTabs from '../../Components/Tabs/DateTabs.jsx';

export default function Dates() {

  const navigate  = useNavigate();

  var FirstPageData = JSON.parse(sessionStorage.getItem("FirstPage")); 
  var LayoutData = JSON.parse(sessionStorage.getItem("Layout"));
  var CalendarData = JSON.parse(sessionStorage.getItem("Calendar")); 
  var AddinsData = JSON.parse(sessionStorage.getItem("Addins"));

  const MaxPages = 242;

  const [tab, setTab] = useState("Dates")
  const [priceBox, setPriceBox] = useState(1);

  const [holidayList, setHolidayList] = useState([]);
  const [holidayDates, setHolidayDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState({});


  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [startDayDrop, setStartDayDrop] = useState(false)
  const [startDayText, setStartDayText] = useState("Sunday Start")

  const [maxLengthDrop, setMaxLengthyDrop] = useState(false)
  const [maxLengthText, setMaxLengthText] = useState("")


  const [defaultHoliday, setDefaultHoliday] = useState(1);
  const [parentCheckboxes, setParentCheckboxes] = useState({});
  const [childCheckboxes, setChildCheckboxes] = useState({});
  const [allDateCheckboxes, setAllDateCheckboxes] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [pageCountError, setPageCountError] = useState(false);

  const cdate = new Date();
  const lastDate = new Date();
  var extraDays = 75;
  if(LayoutData!==undefined && LayoutData!==null ){
    
    if(LayoutData.DailySinglePage!==undefined) {
      extraDays = 120;
    }
    if(LayoutData.DailyTwoPage!==undefined) {
      extraDays = 75;
    }
    if(LayoutData.WeeklyView!==undefined) {
      extraDays = 365;
    }
  }
  lastDate.setDate(lastDate.getDate() + extraDays);

  const handleStartDateChange = (date) => {
    //setStartDate(`${date.$d}`);
    //updateDiaryYear(`${date.$d}`, endDate);
    
    const sdate = new Date(date.$d);
    const startDay = sdate.getDay(); // 0 (Sunday) to 6 (Saturday)

    var startDaysToSubtract = 0;
    setStartDate(sdate);
    const end_date = calculateMaxEndDate(sdate, maxLengthText, MaxPages, FirstPageData, LayoutData, CalendarData, AddinsData);
    setEndDate(end_date);
    
    // Check if the "Dates" session item exists in sessionData
    var NewSessionData = sessionStorage.getItem("Dates");
    if (NewSessionData!=undefined) {
      var NewSessionData = JSON.parse(NewSessionData);
      // If it exists, update the "startDate" and "endDate"
      NewSessionData['EventDate'] = {startDayText: startDayText, startDate: startDate, endDate:endDate, maxLengthText:maxLengthText }; 
      NewSessionData['presentEvent'] = {parentCheckboxes:parentCheckboxes, childCheckboxes:childCheckboxes, selectedDates: selectedDates}
      sessionStorage.setItem("Dates", JSON.stringify(NewSessionData));
      //sessionStorage.setItem("SetDate", 1);
    } else {
      // If it doesn't exist, create a new "Dates" session item
      var dates= {
        EventDate:{startDayText: startDayText, startDate: startDate, endDate:endDate, maxLengthText:maxLengthText },
        presentEvent:{parentCheckboxes:[], childCheckboxes:[], selectedDates: []}
       };
      sessionStorage.setItem("Dates", JSON.stringify(dates));
      //sessionStorage.setItem("SetDate", 1);
    }

    
    
  };

  
  const handleEndDateChange = (date) => {
    setMaxLengthText("Custom")
    setEndDate(`${date.$d}`);
    updateDiaryYear(startDate, `${date.$d}`);
    updateDateSession();
    //sessionStorage.setItem("SetDate", 1);
  }

  const updateDiaryYear = (sdate,edate)=>{
    var FirstPageData = sessionStorage.getItem("FirstPage");
    FirstPageData = JSON.parse(FirstPageData);
    const sdate1 = new Date(sdate);
    const edate1 = new Date(edate);

    var diary_year = sdate1.getFullYear();
    if(sdate1.getFullYear()!==edate1.getFullYear()){
      diary_year = sdate1.getFullYear()+" - "+ edate1.getFullYear()
    }
    if(FirstPageData!==null){
      
      FirstPageData['Year'] = diary_year;
    }
    
    //update years on first page
    sessionStorage.setItem("FirstPage", JSON.stringify(FirstPageData));
  }
  //load api data
  useEffect(() => {
    const BACKEND_URL = config.BACKEND_URL;

    axios.get(`${BACKEND_URL}/api/V1/holiday-dates`)
      .then(response => {
        const { holidayList, holidayDates } = response.data.result;
        setHolidayList(holidayList);
        setHolidayDates(holidayDates);

        Object.keys(holidayList).forEach((subHoliday) => { 
          holidayList[subHoliday].forEach((holiday) => {console.log(holiday.isDefault)
            if (holiday.isDefault === 1) {
              setDefaultHoliday(holiday.id);
            }
          });
        });
        
        
      })
      .catch(error => {
        console.error('Error fetching layout data:', error);
      });

      

  }, [ ]);

  //load data in session
  useEffect(() => { 
   
    //getting session data
    var sessionData = JSON.parse(sessionStorage.getItem("Dates")); 
    if(sessionData!=undefined){
      // Merge default parameters with session data
      //const mergedData = {  ...defaultParameters, ...sessionData };

      setStartDayText(sessionData.EventDate.startDayText);
      setStartDate(new Date(sessionData.EventDate.startDate));
      setEndDate(new Date(sessionData.EventDate.endDate));
      setMaxLengthText(sessionData.EventDate.maxLengthText);
      setParentCheckboxes(sessionData.presentEvent.parentCheckboxes);
      setChildCheckboxes(sessionData.presentEvent.childCheckboxes);
      setSelectedDates(sessionData.presentEvent.selectedDates);
    }
  }, []);

  
  useEffect(() => { 
    updateDateSession();
  },[startDayText,startDate, endDate, maxLengthText, parentCheckboxes, childCheckboxes, selectedDates]);


  const updateDateSession = () =>{
    // Check if the "Dates" session item exists in sessionData
    var NewSessionData = sessionStorage.getItem("Dates");
    if (NewSessionData!=undefined) {
      var NewSessionData = JSON.parse(NewSessionData);
      // If it exists, update the "startDate" and "endDate"
      NewSessionData['EventDate'] = {startDayText: startDayText, startDate: startDate, endDate:endDate, maxLengthText:maxLengthText }; 
      NewSessionData['presentEvent'] = {parentCheckboxes:parentCheckboxes, childCheckboxes:childCheckboxes, selectedDates: selectedDates}
      sessionStorage.setItem("Dates", JSON.stringify(NewSessionData));
      //sessionStorage.setItem("SetDate", 1);
    } else {
      // If it doesn't exist, create a new "Dates" session item
      var dates= {
        EventDate:{startDayText: startDayText, startDate: startDate, endDate:endDate, maxLengthText:maxLengthText },
        presentEvent:{parentCheckboxes:parentCheckboxes, childCheckboxes:childCheckboxes, selectedDates: selectedDates}
       };
      sessionStorage.setItem("Dates", JSON.stringify(dates));
      //sessionStorage.setItem("SetDate", 1);
    }

    setPriceBox(priceBox+1)
  }


  //save data in session
  const handleSaveDates = () => { 
    
    updateDateSession();
    console.log("review")
    navigate('/review');
  }

  const handleNextUrl = () =>{
    navigate('/review')
  }

  const handleHolidayDay = (holiday) => {
    const updatedParentCheckboxes = { ...parentCheckboxes };
    updatedParentCheckboxes[holiday] = !parentCheckboxes[holiday];
    setParentCheckboxes(updatedParentCheckboxes);

    const updatedChildCheckboxes = { ...childCheckboxes };
    for (const subHoliday of holidayList[holiday]) {
      updatedChildCheckboxes[subHoliday.id] = updatedParentCheckboxes[holiday];
    }
    setChildCheckboxes(updatedChildCheckboxes);
  };

  const handleHolidaySubDay = (hdayId) => {
    const updatedChildCheckboxes = { ...childCheckboxes };
    updatedChildCheckboxes[hdayId] = !childCheckboxes[hdayId];
    setChildCheckboxes(updatedChildCheckboxes);
    

    // If all child checkboxes are checked, update the parent checkbox
    const parentHoliday = Object.keys(holidayList).find((holiday) => {
      return holidayList[holiday].every((subHoliday) => updatedChildCheckboxes[subHoliday.id]);
    });
    if (parentHoliday) {
      const updatedParentCheckboxes = { ...parentCheckboxes };
      updatedParentCheckboxes[parentHoliday] = true;
      setParentCheckboxes(updatedParentCheckboxes);
    }

    const updatedAllSelectCheckboxes = { ...allDateCheckboxes };
    updatedAllSelectCheckboxes[hdayId] = !childCheckboxes[hdayId];
    setAllDateCheckboxes(updatedAllSelectCheckboxes);

    const updatedSelectedDates = { ...selectedDates };
    for (const hdate of holidayDates[hdayId]) {
      updatedSelectedDates[hdate.id] = updatedAllSelectCheckboxes[hdayId];
    }
    setSelectedDates(updatedSelectedDates);
    
  };

  const handleAllSelectDate = (hdayId) => {
    const updatedAllSelectCheckboxes = { ...allDateCheckboxes };
    updatedAllSelectCheckboxes[hdayId] = !allDateCheckboxes[hdayId];
    setAllDateCheckboxes(updatedAllSelectCheckboxes);

    const updatedSelectedDates = { ...selectedDates };
    for (const hdate of holidayDates[hdayId]) {
      updatedSelectedDates[hdate.id] = updatedAllSelectCheckboxes[hdayId];
    }
    setSelectedDates(updatedSelectedDates);

    const updatedChildCheckboxes = { ...childCheckboxes };
    updatedChildCheckboxes[hdayId] = !allDateCheckboxes[hdayId];
    //setChildCheckboxes(updatedChildCheckboxes);
  };

  const handleHolidayDate = (hdateId) => {
    const updatedSelectedDates = { ...selectedDates };
    updatedSelectedDates[hdateId] = !selectedDates[hdateId];
    setSelectedDates(updatedSelectedDates);
  };

  const countCheckedSubHoliday = ()=>{
    let trueCount = 0;
    if(childCheckboxes!==null){
      for (const key in childCheckboxes) {
        if (childCheckboxes[key] === true) {
          trueCount++;
        }
      }
    }
    return trueCount;
  }

  const countTotalDates = (hdateId)=>{
    let trueCount = 0;
    if(holidayDates!==null &&  holidayDates[hdateId]!==undefined){
      trueCount = holidayDates[hdateId].length
    }
    return trueCount;
  }

  const countCheckedDates = (hdateId)=>{
    let trueCount = 0;

    if(holidayDates!==null &&  holidayDates[hdateId]!==undefined){
      holidayDates[hdateId].forEach((hdate, index) => {
        if (selectedDates!==null && selectedDates[hdate.id] === true) {
          trueCount++;
        }
      });
    }

    return trueCount;
  }

  const handleMaxDays = (optText)=>{
    setMaxLengthText(optText);
    const start_date = new Date(startDate);
    if(optText === "Max length"){
      const end_date = calculateMaxEndDate(start_date, optText, MaxPages, FirstPageData, LayoutData, CalendarData, AddinsData);
      setEndDate(end_date);
    }
    
    updateDateSession();
  }

  const handleCustomDay = (optText) =>{
    setMaxLengthText(optText);
    const start_date = new Date(startDate);
    const end_date = calculateMaxEndDate(start_date, optText, MaxPages, FirstPageData, LayoutData, CalendarData, AddinsData);
    setEndDate(end_date);
    updateDateSession();
  }

  const calculateMaxEndDate = (sdate, maxText, maxpages, FirstPageData, LayoutData, CalendarData, AddinsData) => {
    
    const MS_PER_DAY = 1; // milliseconds per day
    let edate = new Date(sdate); // Copy the start date
    // Initial assumptions
    if(maxText=="Max length"){
      
    
      let currentPages = 0;
      // console.log("calculateMaxEndDate=>",sdate,edate)
      let i=0;
      while (currentPages < maxpages) {
        i++;
        // Increment the date by one day
        edate.setDate(edate.getDate() + MS_PER_DAY);
      
        //currentPages+=1;
        // Recalculate pages using the updated edate
        const pagesData = calculatePages(sdate, edate, FirstPageData, LayoutData, CalendarData, AddinsData);
        currentPages = pagesData.FirstPages + pagesData.LayoutPages + pagesData.CalendarPages + pagesData.AddinsPages + pagesData.EndPages;
        console.log("Dates Pages=>",{FirstPages:pagesData.FirstPages,EndPages:pagesData.EndPages,LayoutPages:pagesData.LayoutPages,CalendarPages:pagesData.CalendarPages,AddinsPages:pagesData.AddinsPages,PageCount:currentPages})
      }
      if(currentPages > maxpages){
        edate.setDate(edate.getDate() -1);
      }
      
      
    }else{
 
      if(LayoutData!==undefined && LayoutData!==null ){
        var extraDays = 75;
        if(LayoutData.DailySinglePage!==undefined) {
          extraDays = 120;
        }
        if(LayoutData.DailyTwoPage!==undefined) {
          extraDays = 75;
        }
        if(LayoutData.WeeklyView!==undefined) {
          extraDays = 365;
        }
        edate.setDate(edate.getDate() + extraDays);
      }
    }
    console.log("DatePages=>",sdate,edate);
    return edate;
  };

  const calculatePages =(sdate, edate, FirstPageData, LayoutData, CalendarData, AddinsData)=>{
      var FirstPages = (FirstPageData!==undefined) ? 1:0;

      var timeDiff = edate - sdate;
      timeDiff = timeDiff / (1000 * 60 * 60 * 24);
      
      //calculating days, weeks, months and quaters
      const Days = Math.round(timeDiff);
      const Weeks = Math.round(timeDiff /  7);
      const Months = Math.round(timeDiff /  30);
      const Quaters = Math.round(timeDiff /  ( 30 * 3));
      console.log("sdate:",sdate, " edata:",edate," Days:",Days," Weeks:",Weeks," Months=>",Months," Quaters=>",Quaters);

      var LayoutPages = 0;
      if(LayoutData!==undefined && LayoutData!==null){

        if(LayoutData.DailySinglePage!==undefined) {
          LayoutPages = Days;
          if(Days%2==1) LayoutPages++;
        }
        if(LayoutData.DailyTwoPage!==undefined) LayoutPages = Days*2;
        if(LayoutData.WeeklyView!==undefined) LayoutPages = Weeks*2;
      }

      var CalendarPages = 0;
      var onePage = 0;
      var monthPage = 0;
      var extraBackMonth = 0;
      if(CalendarData!==undefined && CalendarData!==null){
        for (const category in CalendarData) {
          if(category=="yearlyTemplateSelected")
          {
            const items = CalendarData[category];
            items.forEach(item => {
              if(item.optType=="Front of Planner" ){
                onePage+=item.count;
              }
              if(item.optType=="Back of Planner"){
                onePage+=item.count;
              }
            });
          }
          if(category=="monthlyTemplateSelected")
          {
            const items = CalendarData[category];
            items.forEach(item => {
              if(item.optType=="Front of Planner" ){
                onePage+=item.count*Months;
              }
              if(item.optType=="Back of Planner"){
                onePage+=item.count*Months;
                extraBackMonth = 1;
              }
              if(item.optType=="Monthly"){
                monthPage+=item.count*Months;
              }
            });
          }
        }

       // console.log("Calendarpages:", onePage, monthPage)
        onePage = onePage*2;
        monthPage =(monthPage*2) + 2;
        if(extraBackMonth===1 && CalendarData['monthlyPlannerRadio']=="246"){
          monthPage += 2*6;   //for 6 months advance calanders
        }
        if(extraBackMonth===1 && CalendarData['monthlyPlannerRadio']=="247"){
          monthPage += 2*12;   //for 12 months advance calanders
        }
        CalendarPages  = onePage + monthPage;
        //console.log("CalendarTotal:", CalendarPages,"=",onePage, monthPage)
      }

      var AddinsPages = 0;
      var onePage = 0;
      var weekPage = 0;
      var monthPage = 0;
      var quarterPage = 0;
      if(AddinsData!==undefined && AddinsData!==null){
        for (const category in AddinsData) {
          const items = AddinsData[category];
          items.forEach(item => {
            if(item.optType=="Front of Planner" || item.optType=="Back of Planner"){
              onePage+=item.count;
            }
            if(item.optType=="Weekly"){
              weekPage+=item.count;
            }
            if(item.optType=="Monthly"){
              monthPage+=item.count;
            }
            if(item.optType=="Quarterly"){
              quarterPage+=item.count;
            }
          });
        }
       // console.log("AddinPages:", onePage, weekPage, monthPage, quarterPage)
        onePage = onePage*2;
        weekPage = (weekPage*Weeks)*2;
        monthPage =(monthPage*Months)*2;
        quarterPage =(quarterPage*Quaters)*2;
        AddinsPages = onePage + weekPage + monthPage + quarterPage;
       // console.log("AddinTotal:", AddinsPages,"=",onePage, weekPage, monthPage, quarterPage)
      }

      const EndPages = 1;
      //console.log("Pages=>",{FirstPages:FirstPages,EndPages:EndPages,LayoutPages:LayoutPages,CalendarPages:CalendarPages,AddinsPages:AddinsPages})
      return {FirstPages:FirstPages,EndPages:EndPages,LayoutPages:LayoutPages,CalendarPages:CalendarPages,AddinsPages:AddinsPages};
  }


  const HolidayBox = (hday)=>{
    return (
      <>
        <Box className="eventHeaderBox">
        <Typography className='presetsEventsHeader'>{hday.title}</Typography>
        <Typography className='checkBoxSubText'>To remove any holidays from your planner, simply uncheck</Typography>
        </Box>
        <Box mt={2} className="presetsEventsBox">
          <Box>
            <Box className="checkBoxItem">
              <input type="checkbox" key={`checkdate-${hday.id}`} 
                        value={hday.id} 
                        checked={allDateCheckboxes[hday.id] || false}
                        onChange={() => handleAllSelectDate(hday.id)} />
              <Typography className='checkBoxSubText' ml={1}>SELECT ALL</Typography>
            </Box>
            {holidayDates[hday.id]!==undefined && holidayDates[hday.id].map((hdate,index) => {
              return (
                <Box className="checkBoxItem">
                   <input type="checkbox" 
                        key={`checkdate-${hdate.id}`} 
                        value={hdate.id} 
                        checked={selectedDates[hdate.id] || false}
                        onChange={() => handleHolidayDate(hdate.id)} />
                  <Typography className='checkBoxSubText' ml={1}>{hdate.title}</Typography>
                </Box>
              )
              })}
          </Box>
        </Box>
      </>
    )
  }

  const handlePageCount = (pcount) =>{
    setPageCount(pcount)
    if(pcount>242){
      setPageCountError(true);
    }else{
      setPageCountError(false);
    }
  }

  return (
    <>
      <NavBar updatePriceBox={priceBox}  nextUrl={handleNextUrl}  updatePageCount={handlePageCount} addBtnFunc={handleSaveDates}/>
      <Box className="PageContainer">
        <Box className="PageBox">
          <Box className="PageInnerBox">
            <Box className="LeftPanelBox noscroll">

              <DateTabs selectedTab={tab} updateTab={setTab} />

              <Box className="LeftPanelInner">

                {/* Start End Drop */}
                <Box sx={{ height: tab==="Dates" ? "auto" : "62px" }} mb={2.5} className={maxLengthDrop? "StartEndDrop datesDrop2":"StartEndDrop datesDrop"}>
                  <Box mb={2} onClick={() => {
                    setTab(tab==="Dates"? "Holidays":"Dates")
                  }} className="StartEndDropHeader">
                    <Typography className="dropBoxHeader" sx={{ color: tab==="Dates" ? "#1e1d1d" : "#8E8E8E" }}>Start and End Date</Typography>
                    <img className="dropBoxNavigateArrow startNavigateArrow" src={tab==="Dates" ? UpArrow : DownArrow} />
                  </Box>
                  <Box className="DropInnerContainer">
                    

                    <Typography className='dropBoxHeader'>Choose the start date for your planner</Typography>
                    <Typography mb={1} className='subText'>(please remember it will take up to 10 days to print and ship your planner)</Typography>
                    <Box mb={2} className="datePickerBox">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker onChange={handleStartDateChange} sx={{ width: "100%" }}  value={dayjs(startDate)} minDate={dayjs(cdate)} />
                      </LocalizationProvider>
                    </Box>

                    <Typography  className='dropBoxHeader'>Choose your length</Typography>
                    <Typography mb={1} className='subText'>How far out do you want your planner to go?</Typography>

                    <Box mb={1} onClick={() => setMaxLengthyDrop(!maxLengthDrop)} className="innerDrop">
                      <Typography className='innerDropText'>{maxLengthText || "Max length"}</Typography>
                      <img className="dropBoxNavigateArrow" src={maxLengthDrop ? UpArrow : DownArrow} />
                      <Box sx={{ height: maxLengthDrop ? "auto" : "0px" }} className="innerDropItemBox">
                        <Box onClick={(e) => handleMaxDays(e.target.innerText)} className="innerDropItem">
                          <p>Max length</p>
                        </Box>
                        <Box onClick={(e) => handleCustomDay(e.target.innerText)} className="innerDropItem">
                          <p>Custom</p>
                        </Box>
                      </Box>
                    </Box>
                    <Typography mb={2} className='subText'> Select ‘max length’ to max out the page count.</Typography>

                    <Typography mb={1.5} className='dropBoxHeader'>Choose the End date for your planner </Typography>
                    <Box className="datePickerBox">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker onChange={handleEndDateChange} sx={{ width: "100%" }}  value={dayjs(endDate)} minDate={dayjs(startDate)} />
                      </LocalizationProvider>
                    </Box>

                    <Typography mt={2} mb={2} className='dropBoxSubHeader'>
                      Please choose a Monday or Sunday start for<br /> your monthly-view calendars.
                    </Typography>

                    <Box mb={2} onClick={() => setStartDayDrop(!startDayDrop)} className="innerDrop">
                      <Typography className='innerDropText'>{startDayText || "Sunday Start"}</Typography>
                      <img className="dropBoxNavigateArrow" src={startDayDrop ? UpArrow : DownArrow} />
                      <Box sx={{ height: startDayDrop ? "auto" : "0px" }} className="innerDropItemBox">
                        <Box onClick={(e) => setStartDayText(e.target.innerText)} className="innerDropItem">
                          <p>Monday Start</p>
                        </Box>
                        <Box onClick={(e) => setStartDayText(e.target.innerText)} className="innerDropItem">
                          <p>Sunday Start</p>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* presets Events Drop */}
                <Box mb={2.5} sx={{ height: tab==="Holidays" ? "auto" : "62px" }} className="presetsDrop datesDrop">
                  <Box mb={2} onClick={() => {
                    setTab(tab==="Holidays"? "Dates":"Holidays")
                  }} className="StartEndDropHeader">
                    <Typography className="dropBoxHeader" sx={{ color: tab==="Holidays" ? "#1e1d1d" : "#8E8E8E" }}>Presets Events</Typography>
                    <img className="dropBoxNavigateArrow startNavigateArrow" src={tab==="Holidays" ? UpArrow : DownArrow} />
                  </Box>
                  <Box mt={0.5} className="DropInnerContainer">


                      {Object.keys(holidayList).map((holiday,index) => {
                        return (
                          <Box mb={1.5} className="checkBoxContainer" key={index}>
                            <Box className="checkBoxItem">

                              <input type="checkbox" 
                              key={`pcheck-${index}`} 
                              value={holiday} 
                              checked={parentCheckboxes[holiday] || false}
                              onChange={() => handleHolidayDay(holiday)} />

                              <Typography ml={1}>{holiday}</Typography>
                            </Box>
                            {holidayList[holiday].map((hdate,index) => {
                                return(
                                  <>
                                    <Box ml={5} className="checkBoxItem">

                                      <input type="checkbox" 
                                      key={`subcheck-${hdate.id}`} 
                                      value={hdate.id}  
                                      checked={childCheckboxes[hdate.id] || false}
                                      onChange={() => handleHolidaySubDay(hdate.id)} />

                                      <Typography className='checkBoxSubText' ml={1}>{hdate.title}({countCheckedDates(hdate.id)}/{countTotalDates(hdate.id)})</Typography>
                                    </Box>
                                  </>
                                )
                            })}
                          </Box>
                        )
                      })}
                      
                  </Box>
                </Box>
                
              </Box>
              
              <Box onClick={handleSaveDates} mt={2} className="st2InputBtn deskbtn">
                <Typography>Next</Typography>
              </Box>  
            
            </Box>


            <Box className="RightPanelBox">

              {/* start end date component*/}
              <Box sx={{ display: tab==="Dates" ? "block" : "none" }} className="StartEndCoverBox">
                <Box className="StartEnd_Cover">
                  <Box sx={{ width: "50%" }}>
                    <Typography mb={1} className='startDateLabel'>Start Date</Typography>
                    <Typography className='SubDate'>{startDate.toString().slice(4).slice(0, 12) || "July 20, 2023"}</Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography mb={1} className='FinishDateLabel'>Finish Dates</Typography>
                    <Typography className='SubDate'>{endDate.toString().slice(4).slice(0, 12)  || "July 20, 2023"}</Typography>
                  </Box>

                  
                </Box>
                <Box className="shipInfo">Planners will print and ships in 10 days.</Box>
                {pageCountError && <Box className="DatePopup"><Box className="CloseDatePopup" onClick={()=>setPageCountError(false)}>X</Box><Typography mt={2} className='PageCountErrorMsg'>Number of pages should not be greater then 242.</Typography></Box>}
                
              </Box>

              {/* presets Events component */ }
              <Box sx={{ display: tab==="Holidays" ? "flex" : "none" }} className="presetsDropBox">

                {Object.keys(holidayList).map((holiday) => (
                  <Box key={holiday}>
                    {holidayList[holiday].map((hdate, index2) => (
                      <Box key={hdate.id} sx={{display: childCheckboxes[hdate.id]? 'block': (countCheckedSubHoliday()==0 && defaultHoliday==hdate.id)? 'block':'none'}}>
                        {HolidayBox(hdate)}
                      </Box>
                    ))}
                  </Box>
                ))}
                
              </Box>
            </Box>
          </Box>
        </Box>
                
     
      </Box>
      <ToastContainer autoClose={false} draggable={false} />
      <Footer />
    </>
  )
}
