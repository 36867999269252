import React, { useState } from 'react';
import { Box } from '@mui/material';

//Component 
import NavBar from '../NavBar';
import Footer from '../Footer';

// pages
import GiftCard from "./GiftCard";
import AccessoryKit from "./AccessoryKit"


export default function Store() {
  const [tab, setTab] = useState("GiftCard");

  const handleTab =(tabname)=>{
    setTab(tabname);
  }


  return (
    <>
      <NavBar  />
      <Box className="PageContainer">
         
          <Box className="PageBox">
            {tab === "GiftCard" ? <GiftCard updateTabPage={handleTab} /> : null}
            {tab === "AccessoryKit" ? <AccessoryKit updateTabPage={handleTab} /> : null}
          </Box>
        </Box>
      <Footer />
    </>
  )
}
