import React, { useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import './style.css';

import cropIcon from "../../Assets/images/crop-icon2.png";
import cropIcon2 from "../../Assets/images/crop_icon.svg";

function ImageCropper({ image, onCropDone, onCropCancel, bleedLine, cropStart }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [cropSize, setCropSize] = useState({ width: 419, height: 544 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(2514/3264);
  const [initialCroppedArea, setInitialCroppedArea] = useState(undefined)
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);

  const minWidth = 800;
  const minHeight = 960;
  const maxWidth = 2514;
  const maxHeight = 3264;

  useEffect(() => {
    let imageObj = new Image();
    imageObj.src = image;
    setImgWidth(imageObj.width)
    setImgHeight(imageObj.height)
    console.log("image0=>",imageObj, imageObj.width, imageObj.height)
  },[])

  useEffect(() => {
    cropStart && onCropConfirm();
  },[cropStart]);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onCropConfirm = () => {
    if (croppedArea && cropStart) {
      onCropDone(croppedArea);
    }
  }
  /*const onCropConfirm2 = () => {
    if (croppedArea && cropStart) {
      let cwidth = croppedArea.width;
      let cheight = croppedArea.height;
      
      // Calculate aspect ratio
      const croppedAspectRatio = cwidth / cheight;

      let width = Math.max(minWidth, croppedArea.width);
      let height = width / croppedAspectRatio;

      // If calculated height is less than minHeight, recalculate width and height
      if (height < minHeight) {
        height = minHeight;
        width = height * croppedAspectRatio;
      }

      // Adjust width and height to fit within maximum dimensions
      if (width > maxWidth) {
        width = maxWidth;
        height = width / croppedAspectRatio;
      }

      if (height > maxHeight) {
        height = maxHeight;
        width = height * croppedAspectRatio;
      }

      // Calculate the final crop area
      const finalCropArea = {
        x: croppedArea.x,
        y: croppedArea.y,
        width,
        height,
      };

      onCropDone(finalCropArea);
    }
  };*/
 
  return (
    <div className="cropper" >
      <div className="crop-container">
        {/* The image you want to crop */}
        {/*<img src={image} alt="Crop" className="crop-image" />*/}

        {/* Add a semi-transparent overlay with a bleed line */}
        <div className={`overlay ${bleedLine ? 'on' : 'off'}`}>
          <div className="bleed-line-horizontal"></div>
          <div className="bleed-line-vertical"></div>
        </div>

        {/* The Cropper component */}
        
        <Cropper
            image={image}
            aspect={aspectRatio}
            crop={crop}
            zoom={zoom}
            onCropComplete={onCropComplete}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            initialCroppedAreaPercentages={initialCroppedArea}
          />
        
      </div>
    </div>
    
  );
}

export default ImageCropper;
