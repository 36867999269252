import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import "./style.css";
import axios from 'axios';
import config from '../../config';


//component
import NavBar from '../NavBar';
import Footer from '../Footer';

export default function PageContent({slug}) {

  const [pageHeading, setPageHeading] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [pageSlug, setPageSlug] = useState("");
  const [pageBanner, setPageBanner] = useState("");

  useEffect(() => {
    const BACKEND_URL = config.BACKEND_URL;
    
    axios.get(`${BACKEND_URL}/api/V1/page/${slug}`)
      .then(response => {
        const { page } = response.data.result;
        setPageHeading(page.title)
        setPageContent(page.content)
        setPageSlug(page.slug)
        setPageBanner(page.banner)
      })
      .catch(error => {
        console.error('Error fetching layout data:', error);
      });

  }, [ slug ]);

 

  // Replace <oembed> with <YouTubeVideo>
  //<figure class="media"><oembed url="https://www.youtube.com/watch?v=WyRK_2sVF9k"></oembed></figure>
  const replacedContent = (pageContent) =>{
    pageContent.replace(
    /<figure class="media"><oembed url="https:\/\/www.youtube.com\/watch\?v=(.*?)"><\/oembed><\/figure>/g,
    (_, videoId) => `<div className="video-container">
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/WyRK_2sVF9k"
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>`
    );
    return pageContent;
  }

  
  
  return (
    <>
      <NavBar />
      <Box className={`PageContainer2 ${slug}-page`}>
        <Box className="PageHeader">
          <Typography className="PageHeaderText">
            {pageHeading}
          </Typography>
          <Box className="PageHeaderNavigation">
            <Typography mr={1}>HOME / </Typography>
            <Typography mr={1}>{pageSlug} </Typography>
          </Box>
        </Box>
        <Box className="PageBox2">
          {pageBanner && <img src={pageBanner} className='pagebanner' alt='' />}
          <Box className="PageContent" dangerouslySetInnerHTML={{ __html: replacedContent(pageContent) }} />
        </Box>
      </Box>
      <Footer />
    </>
  )
}
