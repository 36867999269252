// config.js
const config = {
    FRONTEND_URL: 'https://becoming.neuronsit.in',
    BACKEND_URL: 'https://byb.neuronsit.in',
    //FRONTEND_URL: 'http://localhost:3000',
    //BACKEND_URL: 'http://localhost:8000',
    //FRONTEND_URL: 'https://becomingyourbestplanner.com',
    //BACKEND_URL: 'https://admin.becomingyourbestplanner.com',

    APP_NAME: 'Becoming Your Best Custom Planner',
    GOOGLE_CLIENTID: '906061500366-qovb9nvr7o5qmbl3jj0o9ju3a0grcup9.apps.googleusercontent.com',
    //STRIPE_PUBLISHABLE_KEY: 'pk_test_Pyu4oj7fU3CFtbcP0gH16Ila',
    //STRIPE_SECRET_KEY: 'sk_test_y70bYOyeXF1jzAkFnEXLrbau', 
    STRIPE_PUBLISHABLE_KEY: 'pk_live_05dLszUzqkaZODyXFJbpoITe',
    STRIPE_SECRET_KEY: 'sk_live_Rwk0Ub68HcvLdKyT2aZRRmB9', 
    PAYPAL_CLIENT_ID: 'AXgVDF2d8eaVz34LMH2MbZozPmFeUoOcePzgNz8wX4KrdbeCSzA-4PuSrUlz6cGibKhtr7v1bZ5jOUld',
    PAYPAL_CLIENT_SECRET:'EPfEW2gHKXpJmDHsKztobNvxsKGcLftIcoPCYBoAjlW5928GLR3HbC8XZ1TsYFR-JxmStkCDSzr0W9ME',
    FRIENDBUY_MERCHANT_ID: '13b7e219-985d-4f05-b176-0ea87432f6ee',
    FRIENDBUY_SECRET_KEY: '3775a44a-9b0a-430f-a54a-5e2eb9006192',

    FACEBOOK_ACCOUNT: '3409714159253467',
    FACEBOOK_ACCESSTOKEN: 'EAAFk0ZAsi514BO5xYMjZA9AtEUrM5iZCIZC0cgT9jLJqkuPlWQ17mYG8ZAjhR4jHlnl05wCVaGf8RewC6LM6DXkZCPpLv8t9ZBYyAVcWBlBu3HTlcRa5GDNVc0AeeBaDcEmZCXNxZAOiQNVKe6WRaWZCK2cbWIgPdahjGPlScNSvEkZA7Os9gqBF3CZAVZA12NyoZA5WzbnAZDZD',
    PINTEREST_ACCOUNT: '549767046400',
    PINTEREST_ACCESSTOKEN: 'pina_AIA2RFAWAAT22AQAGBADAD2U432IBDQBAAAAAWOUBCNDZYXXNSTPLEKW7MHCHBDWSD6UNTEZIO5WUNEKRPVZQHQ7GCQQTSAA',
    TIKTOK_ACCOUNT: 'CO0QHOJC77U2P753FORG',
    TIKTOK_ACCESSTOKEN: '3a6db1f0b82aaf066c603bdea16c5de6f62acac8'
  };
  
  export default config;